export async function handleFileUploadCommon(file) {
  if (
    file &&
    !["image/svg+xml", "image/png", "image/jpeg", "application/pdf"].includes(
      file.type
    )
  ) {
    console.log("Only SVG, PNG, and JPG files are allowed.");
    return { Location: "" };
  }

  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/upload/file`, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error uploading image:", error);
    return { Location: "" };
  }
}
